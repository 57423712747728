import '@frontend/ui/css/normalize.css';
import '@frontend/ui/css/base.css';
import '@frontend/ui/css/nordea-sans.css';
import '@frontend/ui/css/material.css';
import 'assets/css/ie11.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ApolloProvider } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { FormikObserverProvider } from '@frontend/formik';
import { defaultTheme as theme } from '@frontend/ui';
import { IntlProviderWrapper } from 'containers/IntlProviderWrapper';
import { NavigationContextProvider } from 'containers/NavigationDrawer/containers/NavigationContext';
import { TransitionOverlayProvider } from 'containers/TransitionOverlayProvider';
import { WithPwaMetaThemeColor } from 'containers/WithPwaMetaThemeColor';
import { GoogleAnalytics } from 'contexts/google/analytics';
import { BannerContextProvider } from 'features/banner';
import ContentfulProvider from 'features/contentful/containers/ContentfulProvider';
import { NotificationProvider } from 'features/notifications';
import { SignBankIdContextProvider } from 'features/sign-graphql/context';
import { basename } from 'index';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter,
  Redirect,
  // eslint-disable-next-line
  Route,
  RouteComponentProps,
} from 'react-router-dom';
import { StyleSheetManager, ThemeProvider, WebTarget } from 'styled-components';

import { client } from './apollo/client';

const RemoveTrailingSlash: React.FC<RouteComponentProps> = ({ location }) => {
  const { pathname } = location;

  if (pathname !== '/' && pathname.slice(-1) === '/') {
    return <Redirect to={pathname.slice(0, -1)} />;
  }
  return null;
};

// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
const shouldForwardProp = (propName: string, target: WebTarget | string) => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
};

interface Props {
  children: React.ReactElement;
}

const Provider: React.FC<Props> = ({ children }) => (
  <ApolloProvider client={client}>
    <IntlProviderWrapper>
      <SignBankIdContextProvider>
        <NotificationProvider>
          <TransitionOverlayProvider>
            <BannerContextProvider>
              <ThemeProvider theme={theme}>
                <StyleSheetManager
                  enableVendorPrefixes
                  shouldForwardProp={shouldForwardProp}
                >
                  <WithPwaMetaThemeColor />
                  <ContentfulProvider>
                    <BrowserRouter basename={basename}>
                      <FormikObserverProvider>
                        <Route path="/" render={RemoveTrailingSlash} />
                        <NavigationContextProvider>
                          <CookiesProvider>
                            <GoogleAnalytics>{children}</GoogleAnalytics>
                          </CookiesProvider>
                        </NavigationContextProvider>
                      </FormikObserverProvider>
                    </BrowserRouter>
                  </ContentfulProvider>
                </StyleSheetManager>
              </ThemeProvider>
            </BannerContextProvider>
          </TransitionOverlayProvider>
        </NotificationProvider>
      </SignBankIdContextProvider>
    </IntlProviderWrapper>
  </ApolloProvider>
);

export default Provider;
